import React from "react"
import { Grid, Link, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';



import style from "./Particulares.module.css"
import servicios from "../../images/law4.jpeg";


let theme = createTheme();
theme = responsiveFontSizes(theme);

const useStyles = makeStyles({
    grid: {
        width: '100%'
    },
    cards: {
        marginTop: "10rem"
    },
    bio2: {
        marginTop: "1rem",
        paddingBottom: "2rem",
         },
     bio2Text: {
        [theme.breakpoints.down('md')]: {
            marginRight: "2rem",
            marginLeft: "2rem"
        },
            marginRight: "4rem",
            marginLeft: "4rem"
             },

})



export default function Particulares () {
    const classes = useStyles()
    return (
        <Grid container direction="column">
            <Grid item>
                 <Grid container direction="column">
                   <Grid item className={style.text}><Typography variant="h3" className={style.textstyle}>Servicios</Typography></Grid>
                  <Grid item className={style.text2}><Typography variant="h5" className={style.textstyle}>A Particulares</Typography></Grid>
                     <Grid item><img src={servicios} alt="ramas de ejercicio" className={style.ramasimage}/></Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.cards}>
            <Grid container direction="column" spacing={3} className={classes.bio2}>
               <Grid item={{ xs: 4, sm: 4, md: 8 }} className={classes.bio2Text}><Typography variant="h6">El procedimiento de atención es inicialmente una consulta, donde el profesional especializado del estudio, dependiendo la temática que consulte el cliente, lo escuchará y orientará en los pasos a seguir. En esta consulta usted puede traer documentación para ser revisada y evacuar todo tipo de preguntas. La duración de la consulta es aproximadamente 1hs. El objeto es conocer en profundidad el relato de los hechos y poder informar adecuadamente del proceso administrativo/ judicial a tratar. La consulta es abonada y usted puede optar por tomarla de modo presencial o virtual.</Typography></Grid>
               <Grid item={{ xs: 4, sm: 4, md: 8 }} className={classes.bio2Text}><Typography variant="h6">Una vez elegido el paso a seguir, si corresponde iniciar algún tipo de proceso administrativo y/o judicial el profesional le asesorará de los pro y contra del mismo. Dependiendo del tiempo aproximado estimado se evaluará a conveniencia del cliente el pago de los honorarios, usted podrá optar por financiaciones particulares o el pago con tarjetas de crédito.</Typography></Grid>
               <Grid item={{ xs: 4, sm: 4, md: 8 }} className={classes.bio2Text}><Typography variant="h6">Al ofrecer un SERVICIO INTEGRAL usted tendrá un trato directo a diario con su profesional, también le ofreceremos otro tipo de profesionales  de nuestro equipo, en los cuales confiamos para integrar al proceso, en tal caso contamos con psicólogos, peritos, contadores etc. Para ofrecerle.-
No dudes en consultarnos</Typography></Grid>
               </Grid>
            </Grid>
        </Grid>
    )

}