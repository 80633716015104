import React from "react";
import {Card, CardActions, CardContent, CardMedia, Button, Typography} from "@mui/material";
import useStyles from "./styles";
import DeleteIcon from '@mui/icons-material/Delete';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import moment from "moment";
import {useDispatch} from 'react-redux';
import { deletePost } from "../../../actions/posts";

export default function Post( {post, setCurrentId} ) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const scrollToTop = () =>{
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
        });
      };


    const handleClick = () => {
      setCurrentId(post._id);
      scrollToTop();
    }

    return (
        <Card className={classes.card}>
            <CardMedia className={classes.media} image={post.selectedFile} title={post.title}/>
            <div className={classes.overlay}>
                <Typography variant="body2">{moment(post.createdAt).fromNow()}</Typography>
            </div>
            <div className={classes.overlay2}>
                <Button style={{color: 'white'}} size="medium" onClick={() => handleClick()}>
                    <MoreHorizIcon fontSize="medium"/>
                </Button>
            </div>
            <div className={classes.details}>
                <Typography variant="body2" color="textSecondary">Abogada Barbara Olivieri</Typography>
            </div>
                <Typography className={classes.title} variant="h6" gutterBottom>{post.title.slice(0,30)}</Typography>
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">{post.message.slice(0,60)}</Typography>
            </CardContent>
            <div className={classes.cardActions}>
                <Button size="small" color="error" onClick={() => dispatch(deletePost(post._id))}>
                    <DeleteIcon fontSize="small"/>
                    Apagar
                </Button>
            </div>
        </Card>
    )
    
}