import React from "react"
import { Grid, Typography, useMediaQuery, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

import law from "../../images/main.jpeg";
import molde from "../../images/molde.png";
import office1 from "../../images/office1.jpeg";
import office2 from "../../images/office2.jpeg";
import style from "./Home.module.css"
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);


const useStyles = makeStyles({
    grid: {
        width: '100%',
        backgroundColor: '#ECF2FF'
    },
    lawimage: {
        [theme.breakpoints.down('md')]: {
            position: "absolute",
            top: 0,
            left: 0,
            minHeight: "25rem",
            maxHeight: "25rem",
            width: "100%",
            objectFit: "cover",
            zIndex: 2,
        },
        [theme.breakpoints.up('md')]: {
            position: "absolute",
            top: 0,
            left: 0,
            minHeight: "30rem",
            maxHeight: "30rem",
            width: "100%",
            objectFit: "cover",
            zIndex: 2,
        },
        [theme.breakpoints.up('lg')]: {
            position: "absolute",
            top: 0,
            left: 0,
            minHeight: "35rem",
            maxHeight: "35rem",
            width: "100%",
            objectFit: "cover",
            zIndex: 2,
        },
 
    },
    text2: {
        color: "white",
        zIndex: 3,
    },
    text: {
        [theme.breakpoints.down('md')]: {
            marginTop: "10rem",
            color: "white",
            zIndex: 3,
        },
        [theme.breakpoints.up('md')]: {
            marginTop: "10rem",
            color: "white",
            zIndex: 3,
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: "10rem",
            color: "white",
            zIndex: 3,
        },
      },

      textsobremi: {
        [theme.breakpoints.down('md')]: {
            marginTop: "10rem",
            color: "black",
        },
        [theme.breakpoints.up('md')]: {
            marginTop: "15rem",
            color: "black",
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: "18rem",
            color: "black",
        },
      },
      item1: {
        [theme.breakpoints.down('md')]: {
            marginBottom: "1rem",
            color: "white",
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: "1rem",
            color: "white",
        },
        [theme.breakpoints.up('lg')]: {
            marginBottom: "1rem",
            color: "white",
        },
      },

      molde: {
        [theme.breakpoints.down('md')]: {
            minHeight: "20rem",
            maxHeight: "20rem",
        },
        [theme.breakpoints.up('md')]: {
            minHeight: "25rem",
            maxHeight: "25rem",
        },
        [theme.breakpoints.up('lg')]: {
            minHeight: "35rem",
            maxHeight: "35rem",
        },
      },
      bio: {
     paddingRight: "0.5rem"
      },
      bio2: {
        marginTop: "1rem",
        backgroundColor: "#D6E4E5",
        paddingBottom: "2rem",
         },
     bio2Text: {
        [theme.breakpoints.down('md')]: {
            marginRight: "1rem",
            marginLeft: "1rem"
        },
            marginRight: "3rem",
            marginLeft: "3rem"
             },
     bio3: {
        marginTop: "1.5rem",
        paddingBottom: "2rem",
        marginRight: "4rem"
         },

         office: {
            [theme.breakpoints.down('md')]: {
                minHeight: "12rem",
                maxHeight: "12rem",
            },
            [theme.breakpoints.up('md')]: {
                minHeight: "25rem",
                maxHeight: "25rem",
            },
            [theme.breakpoints.up('lg')]: {
                minHeight: "23rem",
                maxHeight: "23rem",
            },
          },
})


export default function Home () {
    const classes = useStyles()
    const xs = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <Grid container  direction="column" columns={{ xs: 4, sm: 8, md: 12 }} className={classes.grid}> 
            <Grid item className={classes.item1} >
                  <ThemeProvider theme={theme}>
                <Grid container direction="column" spacing={2}>
                <Grid item={{ xs: 4, sm: 8, md: 12 }}><img src={law} alt="abogada" className={classes.lawimage}/></Grid>
                <Grid item={{ xs: 4, sm: 8, md: 12 }} alignSelf="center" className={classes.text}>
                    <Typography variant={xs? "h4":"h3"} fontFamily="Poppins">Servicios Jurídicos Integrales</Typography></Grid>
                <Grid item={{ xs: 4, sm: 8, md: 12 }} alignSelf="center"  className={classes.text2}><Typography  fontFamily="Poppins" variant="h5">Dra. Barbara Ivon Olivieri y Asociados</Typography></Grid>
                </Grid>
                 </ThemeProvider>
            </Grid>
            <Grid item>
             <Grid container direction="column" spacing={2}>
             <Grid item={{ xs: 4, sm: 8, md: 12 }} alignSelf="center" className={classes.textsobremi}>
                    <Typography variant={xs? "h5":"h4"} fontFamily="Poppins">Sobre Mi</Typography></Grid>
             </Grid>
             <Grid item >
                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item={{ xs: 4, sm: 4, md: 4 }} marginRight="2rem" maxWidth="30rem"><img src={molde} alt="Barbara Olivieri" className={classes.molde} /></Grid>
                  <Grid item={{ xs: 4, sm: 4, md: 4 }} >
                <Grid container maxWidth="45rem"  direction="column" spacing={3} className={classes.bio}>
                  <Grid item={{ xs: 4, sm: 4, md: 8 }}><Typography variant="h6">Hola! Mi nombre es Barbara Ivon Olivieri, tengo 36 años, resido en la Ciudad de Neuquén, Patagonia Argentina, ciudad que amo y elegí como sede central de mi estudio jurídico “Servicios Jurídicos Integrales”.</Typography></Grid>
                  <Grid item={{ xs: 4, sm: 4, md: 8 }}><Typography variant="h6">Mi formación profesional comenzó en la Ciudad de Córdoba, estudié abogacía en la Universidad Blas Pascal, con toda la utopía y sentido de justicia que una adolescente de 18 años y militante puede tener! … a medida que iba conociendo de las materias y ejercicio profesional pude confirmar que esta carrera era mi vocación y pasión. </Typography></Grid>
                  <Grid item={{ xs: 4, sm: 4, md: 8 }}><Typography variant="h6">Mientras cursaba la carrera me ofrecieron cubrir unas pasantías en un emblemático estudio jurídico ad honorem, así accedí al primer contacto con la realidad del ejercicio profesional y más aun, de la justicia, la verdad es que esta primera experiencia fue determinante, allí decidí que al recibirme ejercería la profesión liberal, es decir que montaría mi estudio jurídico y trabajaría ofreciendo mis servicios profesionales a clientes particulares y empresas, así fue como se gestó este espacio. </Typography></Grid>
                  </Grid>
                  </Grid>
                </Grid>
             </Grid>
             <Grid item >
               <Grid container direction="column" spacing={3} className={classes.bio2}>
               <Grid item={{ xs: 4, sm: 4, md: 8 }} className={classes.bio2Text}><Typography variant="h6">Desde entonces llevo nueve años trabajando de modo independiente, hoy con un sentido más realista de la justicia, de los conflictos y de la expectativa de cada parte cuando acude a mi con su situación. He podido comprender que las partes buscan resolver, la practicidad e inmediatez, situaciones que muchas veces no son compatibles con la burocracia, por ello casi de modo innato comencé a negociar y conciliar como alternativa a los procesos judiciales;  tratando de ofrecerles otro tipo de justicia, una justicia representativa de sus intereses inmediatos.</Typography></Grid>
               <Grid item={{ xs: 4, sm: 4, md: 8 }} className={classes.bio2Text}><Typography variant="h6"> Mientras ofrecía estas alternativas muy distintas a la formación dogmatica que me enseñaron en la facultad, noté que  los procesos se acotaron en el tiempo, los conflictos se resolvían mejor y mis clientes me agradecían felices.  Debido a ello, me empecé a formar en negociación y conciliación, también en el desarrollo del comportamiento emprendedor en el Centro Pymes Adeneu de Neuquén, estos talleres fueron clave para potenciar mi perfil,  también gratamente, hace poco logre obtener mi título como Mediadora profesional de resolución de conflictos, en el instituto Redes Alternativas, dirigido por el gran Antonio Tula.</Typography></Grid>
               </Grid>
             </Grid>
             <Grid item >
                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item={{ xs: 4, sm: 4, md: 4 }}>
                <Grid container maxWidth="40rem"  direction="column" spacing={3} className={classes.bio3}>
                <Grid item={{ xs: 4, sm: 8, md: 12 }} alignSelf="center">
                <Typography variant={xs? "h5":"h4"} fontFamily="Poppins">Introducción al Estudio</Typography> </Grid>
                  <Grid item={{ xs: 4, sm: 4, md: 8 }}><Typography variant="h6">La visión de este Estudio Jurídico y Centro Privado de Mediación: procuramos, ofrecer a nuestros clientes, la resolución de modo eficaz e inmediato al  asunto que nos convoca, utilizamos herramientas técnicas de negociación, conciliación y mediación para agotar las alternativas previo ir a un proceso judicial, elaborando convenios altamente eficaces los cuales homologamos por sentencia judicial.</Typography></Grid>
                  <Grid item={{ xs: 4, sm: 4, md: 8 }}><Typography variant="h5">Centro Privado de Mediación:</Typography></Grid>
                  <Grid item={{ xs: 4, sm: 4, md: 8 }}><Typography variant="h6">¿Que asuntos podemos mediar o negociar ? Todos los asuntos. Causas civiles, de accidente de tránsito, incumplimientos contractuales, reclamos de defensa al consumidor, Causas de familia, alimentos, régimen de comunicación, Causas penales, robos, excarcelaciones etc. Aclaramos que no deben existir medidas cautelares vigentes que impidan que las partes se reúnan para poder mediar.</Typography></Grid>
                  </Grid>
                  </Grid>
                  <Grid item={{ xs: 4, sm: 4, md: 4 }} marginRight="2rem" maxWidth="20rem"><img src={office1} alt="Barbara Olivieri" className={classes.office} /></Grid>
                </Grid>
             </Grid>
            </Grid>
            <Grid className={style.box} width="100%">
        <Grid container direction="column" alignItems='center' spacing={1}>
            <Grid item><Typography color="#577D86" variant="h6">Agende una cita para más información</Typography></Grid>
            <Grid item><LocationOnIcon color="error"></LocationOnIcon></Grid>
            <Grid item><Typography color="#577D86" variant="h6">Santa Fe 543, Primer piso, Oficina 4</Typography></Grid>
            <Grid item><Typography color="#577D86" variant="h5">Neuquén Capital</Typography></Grid>
            <Grid item><Typography color="#577D86" variant="h5">Dra. Barbara Ivon Olivieri</Typography></Grid>
            <Grid item><Typography color="#577D86" variant="h5">y Asociados</Typography></Grid>

        </Grid>
                    </Grid>
        </Grid>
    )

}