import React from "react"
import { Grid, Link, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';



import style from "./Empresas.module.css"
import servicios from "../../images/law5.jpeg";


let theme = createTheme();
theme = responsiveFontSizes(theme);

const useStyles = makeStyles({
    grid: {
        width: '100%'
    },
    cards: {
        marginTop: "10rem"
    },
    bio2: {
        marginTop: "1rem",
        paddingBottom: "2rem",
         },
     bio2Text: {
        [theme.breakpoints.down('md')]: {
            marginRight: "2rem",
            marginLeft: "2rem"
        },
            marginRight: "4rem",
            marginLeft: "4rem"
             },

})



export default function Empresas () {
    const classes = useStyles()
    const texto = "Asesoramiento integral, consultas libres personal, telefónica o por correo electrónico._ Asistencia a mediaciones y conciliaciones laborales._ Acuerdos ante autoridades administrativas (Ministerio de Trabajo)._Defensas en juicios laborales. Defensa en juicios civiles y/o comerciales._ Actuación en juicios civiles y/o comerciales como parte actora o demandada._Ejecución de títulos comerciales (cheque, pagaré, etc.)._Recupero judicial y extrajudicial de deudas._Presentaciones en Concursos y Quiebras._Seguimiento de juicios pendientes de resolución._Redacción de contratos.Control de documentación: contratos, etc._Presentaciones en sede administrativa._Seguimiento de casos ante la A.R.T. contratada por la empresa._Informe mensual sobre el estado de las causas (judiciales y extrajudiciales)._Visitas personales de abogados en la sede de la empresa."
    const textarray = texto.split("_")
    const texto2 = "Asesoramiento laboral, consultas libres personal, telefónica o por correo electrónico._Asistencia en conciliaciones laborales._Acuerdos ante autoridades administrativas (Ministerio de Trabajo)._Defensas en juicios laborales._Seguimiento de juicios pendientes de resolución._Entrevistas con los empleados de la empresa._Seguimiento de casos ante la A.R.T. contratada por la empresa._Informe mensual sobre el estado de las causas (judiciales y extrajudiciales)._Visitas personales de abogados en la sede de la empresa."
    const textarray2 = texto2.split("_")
    return (
        <Grid container direction="column">
            <Grid item>
                 <Grid container direction="column">
                   <Grid item className={style.text}><Typography variant="h3" className={style.textstyle}>Servicios</Typography></Grid>
                  <Grid item className={style.text2}><Typography variant="h5" className={style.textstyle}>A Empresas</Typography></Grid>
                     <Grid item><img src={servicios} alt="ramas de ejercicio" className={style.ramasimage}/></Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.cards}>
            <Grid container direction="column" spacing={3} className={classes.bio2}>
            <Grid item className={style.text2}><Typography variant="h5" className={style.textstyle}>SERVICIO DE ABONO MENSUAL</Typography></Grid>
               <Grid item={{ xs: 4, sm: 4, md: 8 }} className={classes.bio2Text}><Typography variant="h6">A través del servicio de abono mensual, su empresa o comercio o sindicato o institución o particular, podrá tener un profesional para responder a sus consultas, a fin de brindar asesoramiento integral, asistencia letrada y demás cuestiones jurídicas y legislativas en cuanto asunto de injerencia a las áreas de nuestro Estudio Jurídico y Consultoría Legislativa, beneficios que podrán acceder mediante la contratación de un abono mensual a convenir:</Typography></Grid>
               <Grid item className={style.text2}><Typography variant="h5" className={style.textstyle}>ABONO 1 – PLAN BASICO</Typography></Grid>
               <Grid item={{ xs: 4, sm: 4, md: 8 }} className={classes.bio2Text}><Typography variant="h6" > Asesoramiento integral, consultas libres personal, telefónica o por correo electrónico.</Typography></Grid>
              <Grid item={{ xs: 4, sm: 4, md: 8 }} className={classes.bio2Text}><Typography variant="h6" >Redacción de contratos.</Typography></Grid>
              <Grid item={{ xs: 4, sm: 4, md: 8 }} className={classes.bio2Text}><Typography variant="h6" >Control de documentación: contratos, etc.</Typography></Grid>
              <Grid item={{ xs: 4, sm: 4, md: 8 }} className={classes.bio2Text}><Typography variant="h6" >Contestar y enviar cartas documentos.</Typography></Grid>
              <Grid item className={style.text2}><Typography variant="h5" className={style.textstyle}>ABONO 2 PLAN INTEGRAL</Typography></Grid>
              {textarray.map(element => (
                              <Grid item={{ xs: 4, sm: 4, md: 8 }} className={classes.bio2Text}><Typography variant="h6" >{element}</Typography></Grid>
              ))}
                             <Grid item className={style.text2}><Typography variant="h5" className={style.textstyle}>ABONO 3 PLAN LABORAL</Typography></Grid>
               {textarray2.map(element => (
                              <Grid item={{ xs: 4, sm: 4, md: 8 }} className={classes.bio2Text}><Typography variant="h6" >{element}</Typography></Grid>
              ))}
               </Grid>
            </Grid>
        </Grid>
    )

}