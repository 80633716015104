import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

import derechocivil from "../../images/derechocivil.jpeg";

export default function CardCivil() {
  return (
    <Card sx={{ minHeight: 475 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="235rem"
          image={derechocivil}
          alt="Derecho Civil"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Derecho Civil
          </Typography>
          <Typography variant="h6" color="text.secondary">
          Acciones por accidentes de tránsito, demandas por incumplimientos contractuales, ejecuciones de deuda, redacción de contratos.
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}