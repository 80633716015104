import {makeStyles} from "@material-ui/styles"
import { createTheme, responsiveFontSizes } from '@mui/material/styles';


let theme = createTheme();
theme = responsiveFontSizes(theme);


export default makeStyles({
    media: {
      height: 0,
      paddingTop: '56.25%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      backgroundBlendMode: 'darken',
    },
    border: {
      border: 'solid',
    },
    fullHeightCard: {
      height: '100%',
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      borderRadius: '15px',
      height: '25rem',
      width: '20rem',
      position: 'relative',
    },
    overlay: {
      position: 'absolute',
      top: '20px',
      left: '20px',
      color: 'white',
      backgroundColor: '#577D86',
      borderRadius: "10%",
      padding: "3px"
    },
    overlay2: {
      position: 'absolute',
      top: '20px',
      right: '20px',
      color: 'white',
    },
    grid: {
      display: 'flex',
    },
    details: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '20px',
    },
    title: {
      padding: '0 16px',
    },

    titlebox: {
      minHeight: '5rem',
    },
    cardActions: {
      display: "flex",
      alignItems: "flex-end",
    },

    cardmain: {
      [theme.breakpoints.down('sm')]: {
          minHeight: "38rem",
          maxHeight: "38rem",
          minWidth: "23rem",
          maxWidth: "23rem",
      },
      [theme.breakpoints.up('sm')]: {
        minHeight: "38rem",
        maxHeight: "38rem",
        minWidth: "30rem",
        maxWidth: "30rem",
      },

      [theme.breakpoints.up('md')]: {
        minHeight: "38rem",
        maxHeight: "38rem",
        minWidth: "30rem",
        maxWidth: "30rem",
      },
      [theme.breakpoints.up('lg')]: {
        minHeight: "38rem",
        maxHeight: "38rem",
        minWidth: "30rem",
        maxWidth: "30rem",
      },
    },
  });