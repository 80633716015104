import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {CardActionArea } from '@mui/material';

import derechopenal from "../../images/derechopenal.jpeg";

export default function Cardpenal() {
  return (
    <Card sx={{ minHeight: 475 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="235rem"
          image={derechopenal}
          alt="Derecho Penal"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Derecho Penal
          </Typography>
          <Typography variant="h6" color="text.secondary">
          Guardias 24hs. Representación en etapa de investigación preventiva, imputación de cargos y juicio. Materia: robo, hurto, accidentes, violencia.  Se excluye causas de abuso sexual.
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}