import React from "react";
import Post from "./Post/Post";
import {Grid, CircularProgress} from "@mui/material"
import useStyles from "./styles"
import {useSelector} from "react-redux"

export default function Posts({setCurrentId}) {
    const posts = useSelector((state) => state.posts)
    const classes = useStyles();
    return (
    !posts.length ? <CircularProgress/> : (
        <Grid className={classes.mainContainer} container alignItems="stretch" spacing={3}>
            {posts.map((post) => (
                <Grid item key={post._id} xs={12} sm={4}>
                    <Post post={post} setCurrentId={setCurrentId}/>
                </Grid>
            ))}
        </Grid>
    )
    )
}