import React from "react"
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';


import ramas from "../../images/grey.jpeg";
import Cardfamiliar from "./Cardfamiliar"
import CardCivil from "./Cardcivil"
import Cardlaboral from "./Cardlaboral"
import Cardpenal from "./Cardpenal"


import style from "./Ramasdeejercicio.module.css"
import Cardempresarial from "./Cardempresarial";


let theme = createTheme();
theme = responsiveFontSizes(theme);


const useStyles = makeStyles({
    grid: {
        width: '100%'
    },
    cards: {
        marginTop: "10rem"
    },

})


export default function Ramasdeejercicio () {
    const classes = useStyles()
    return (
        <Grid container direction="column">
            <Grid item>
                 <Grid container direction="column">
                   <Grid item className={style.text}><Typography variant="h3" className={style.textstyle}>Ramas de Ejercicio</Typography></Grid>
                  <Grid item className={style.text2}><Typography variant="h5" className={style.textstyle}>Actuando en diversas areas....</Typography></Grid>
                     <Grid item><img src={ramas} alt="ramas de ejercicio" className={style.ramasimage}/></Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.cards}>
              <Grid container direction="row" spacing={5} justifyContent="center" alignItems="center">
            <Grid item maxWidth="35rem"><Cardfamiliar></Cardfamiliar></Grid>
            <Grid item maxWidth="35rem"><CardCivil></CardCivil></Grid>
            <Grid item maxWidth="35rem"><Cardlaboral></Cardlaboral></Grid>
            <Grid item maxWidth="35rem"><Cardpenal></Cardpenal></Grid>
            <Grid item maxWidth="35rem"><Cardempresarial></Cardempresarial></Grid>
              </Grid>
            </Grid>
        </Grid>
    )

}