import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {Card, CardActions, CardContent, CardMedia, Button, Typography, CardActionArea} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import moment from "moment";
import useStyles from "./styles";
import {useDispatch} from 'react-redux';
import { deletePost } from "../../actions/posts";
import 'moment/locale/es';

moment.locale('es')


export default function CardNoticias( {post, setCurrentId} ) {
    const classes = useStyles();
    const navigate = useNavigate(); 
    const dispatch = useDispatch();

    const scrollToTop = () =>{
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
        });
      };


    const handleClick = () => {
      setCurrentId(post._id);
      scrollToTop();
    }

    return (
<Card sx={{ minHeight: 500, minWidth: 500, maxWidth: 500  }} className={classes.cardmain}>
      <CardActionArea>
      <div className={classes.overlay}>
                <Typography variant="body2">{moment(post.createdAt).fromNow()}</Typography>
            </div>
        <CardMedia
          component="img"
          height="235rem"
          width="535rem"
          image={post.selectedFile}
          alt="Derecho Familiar"
        />
                <div className={classes.details}>
                <Typography variant="body2" color="textSecondary">Abogada Barbara Olivieri</Typography>
            </div>
        <CardContent>
        <div className={classes.titlebox}>
          <Typography gutterBottom variant="h6" fontSize="22px" component="div">
          {post.title.split(' ').length > 10? post.title.split(' ', 10).join(' ').concat("....") : post.title}
          </Typography>
          </div>
          <Typography variant="h6" color="text.secondary">
          {post.message.split(' ', 15).join(' ').concat("....")}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.cardActions}>
                <Button size="small" color="success" variant="contained" onClick={() => navigate(`/noticias/${post._id}`)}>
                    <ExpandMoreIcon fontSize="small"/>
                    Ver más
                </Button>
            </CardActions>
    </Card>
    )
    
}