import React from "react"
import { Grid, Link, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';



import style from "./Mediacion.module.css"
import servicios from "../../images/law4.jpeg";


let theme = createTheme();
theme = responsiveFontSizes(theme);

const useStyles = makeStyles({
    grid: {
        width: '100%'
    },
    cards: {
        marginTop: "10rem"
    },
    bio2: {
        marginTop: "1rem",
        paddingBottom: "2rem",
         },
     bio2Text: {
        [theme.breakpoints.down('md')]: {
            marginRight: "2rem",
            marginLeft: "2rem"
        },
            marginRight: "4rem",
            marginLeft: "4rem"
             },

})



export default function Mediacion () {
    const classes = useStyles()
    return (
        <Grid container direction="column">
            <Grid item>
                 <Grid container direction="column">
                   <Grid item className={style.text}><Typography variant="h3" className={style.textstyle}>Servicios</Typography></Grid>
                  <Grid item className={style.text2}><Typography variant="h5" className={style.textstyle}>Centro Privado de Mediaciones</Typography></Grid>
                     <Grid item><img src={servicios} alt="ramas de ejercicio" className={style.ramasimage}/></Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.cards}>
            <Grid container direction="column" spacing={3} className={classes.bio2}>
               <Grid item={{ xs: 4, sm: 4, md: 8 }} className={classes.bio2Text}><Typography variant="h5">¿QUÉ ES LA MEDIACIÓN? </Typography></Grid>
               <Grid item={{ xs: 4, sm: 4, md: 8 }} className={classes.bio2Text}><Typography variant="h6">Es un procedimiento voluntario mediante el cual las personas o partes, con el apoyo de un Mediador profesional pueden comunicarse y negociar los temas que los convocan para encontrar de manera amigable y satisfactoria la solución legal a su problema de carácter Civil-Mercantil, Familiar, o empresarial. Es una alternativa de resolución del conflicto o tratativas, que evitan el proceso judicial.</Typography></Grid>
               <Grid item={{ xs: 4, sm: 4, md: 8 }} className={classes.bio2Text}><Typography variant="h6">La Dra. Barbara Ivon Olivieri se especializo en esta área profesional en la institución Redes Alternativas, de la mano del Dr. Antonio Tula, eminencia en la materia. Así mismo esta opción para resolver el conflicto que trae el cliente, es una prioridad para la tratativa del mismo, que la Dra. Ofrece primariamente antes de recurrir a la vía judicial.</Typography></Grid>
               <Grid item={{ xs: 4, sm: 4, md: 8 }} className={classes.bio2Text}><Typography variant="h6">También nos especializamos en negociaciones como métodos estratégicos para alcanzar el interés del cliente. Consulte por este servicio 》》》</Typography></Grid>
               </Grid>
            </Grid>
        </Grid>
    )

}