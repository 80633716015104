import {makeStyles} from "@material-ui/styles"

export default makeStyles(() => ({
    appBar: {
        borderRadius: 15,
        margin: '30px 0',
        display: 'flex',
        flexDirection: '',
        justifyContent: 'center',
        alignItems: 'center',
        
      },
      paper: {
        borderRadius: "15",
        marginTop: '10rem',
        alignContent: "center",
        maxWidth: "20rem",
      },

      heading: {
        color: 'rgba(0,183,255, 1)',
      },
      image: {
        marginLeft: '15px',
      },
      root: {
        '& .MuiTextField-root': {
          margin: "1rem",
        },
      },
      form: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
      },
      fileInput: {
        width: '10%',
        margin: '10px 0',
      },
      buttonSubmit: {
        marginBottom: 10,
      },
}))