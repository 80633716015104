import React, {useEffect} from "react";
import { Grid, Typography } from "@mui/material";
import noticias from "../../images/noticias.png";
import {useSelector} from "react-redux"
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';



import style from "./Noticias.module.css"
import CardNoticias from "./card";
import { getPosts } from "../../actions/posts";
import { useParams } from "react-router-dom";

let theme = createTheme();
theme = responsiveFontSizes(theme);

const useStyles = makeStyles({
    grid: {
        width: '100%'
    },
    cards: {
        marginTop: "8.5rem"
    },

    nonews: {
        color: "black",
        marginTop: "15rem"
    },

    date: {
        marginRight: "2rem"
    },

    molde: {
        [theme.breakpoints.down('sm')]: {
            minHeight: "15rem",
            maxHeight: "15rem",
        },
        [theme.breakpoints.up('sm')]: {
            minHeight: "25rem",
            maxHeight: "25rem",
        },

        [theme.breakpoints.up('md')]: {
            minHeight: "25rem",
            maxHeight: "25rem",
        },
        [theme.breakpoints.up('lg')]: {
            minHeight: "35rem",
            maxHeight: "35rem",
        },
      },

      title: {
        marginRight: "2rem",
        marginLeft: "2rem",
    },

    titletext: {
            [theme.breakpoints.down('sm')]: {
                fontSize: "26px"
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: "36px"
            },
    
            [theme.breakpoints.up('md')]: {
                fontSize: "40px"
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: "45px"
            },
          
    },

    message: {
        marginRight: "2rem",
        marginLeft: "2rem"
    },

})



export default function Detalhes ()  {
    const dispatch = useDispatch();
    const params = useParams();
    const posts = useSelector((state) => state.posts)
  

    useEffect(() => {
        dispatch(getPosts())
    }, [dispatch]);

    let details = posts.filter(ele => ele._id === params.id)
    const classes = useStyles();
    console.log(details)

 
    const paragraphs = posts.length > 0?  details[0].message.split('//') : "Cargando..."

    return ( 
        <Grid container direction="column">
        <Grid item>
             <Grid container direction="column">
               <Grid item className={style.text}><Typography variant="h3" className={style.textstyle}>Noticias</Typography></Grid>
              <Grid item className={style.text2}><Typography variant="h4" className={style.textstyle}>Ultimas publicaciones</Typography></Grid>
                 <Grid item><img src={noticias} alt="ramas de ejercicio" className={style.ramasimage}/></Grid>
            </Grid>
        </Grid>
        {posts.length > 0 &&
        <Grid item className={classes.cards}>
              <Grid container direction="column" spacing={5} justifyContent="center" alignItems="center">
              <Grid item className={classes.date} alignSelf='flex-end'>
                <Typography variant="h6">{details[0].createdAt.slice(8,10) +"-"+ details[0].createdAt.slice(5,7) +"-"+ details[0].createdAt.slice(0,4)}</Typography>
                </Grid>
              <Grid item >
                <img  src={details[0].selectedFile} className={classes.molde}/>
              </Grid>
              <Grid item className={classes.title}>
                <Typography variant="h5" className={classes.titletext}>{details[0].title}</Typography>
                </Grid>
                { paragraphs.map(ele => 
                      <Grid item className={classes.message} alignSelf="flex-start">
                      <Typography variant="h6">{ele}</Typography>
                      </Grid>
                )
              }

              </Grid>
            </Grid>}
        </Grid>
    )

    

}