import React, {useEffect} from "react";
import { Grid, Typography } from "@mui/material";
import noticias from "../../images/noticias.png";
import {useSelector} from "react-redux"
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';



import style from "./Noticias.module.css"
import CardNoticias from "./card";
import { getPosts } from "../../actions/posts";

let theme = createTheme();
theme = responsiveFontSizes(theme);


const useStyles = makeStyles({
    grid: {
        width: '100%'
    },
    cards: {
        marginTop: "10rem"
    },

    nonews: {
        color: "black",
        marginTop: "15rem"
    },

})



export default function Noticias ({setCurrentId})  {
    const posts = useSelector((state) => state.posts)
    let sortedArray = posts.sort((a,b)=> new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPosts())
    }, [dispatch]);


 if(posts.length > 0) {
    return (
        <Grid container direction="column">
        <Grid item>
             <Grid container direction="column">
               <Grid item className={style.text}><Typography variant="h3" className={style.textstyle}>Noticias</Typography></Grid>
              <Grid item className={style.text2}><Typography variant="h4" className={style.textstyle}>Ultimas publicaciones</Typography></Grid>
                 <Grid item><img src={noticias} alt="ramas de ejercicio" className={style.ramasimage}/></Grid>
            </Grid>
        </Grid>
        <Grid item className={classes.cards}>
              <Grid container direction="row" spacing={5} justifyContent="center" alignItems="center">
              {sortedArray.map((post) => (
                <Grid item key={post._id} maxWidth="35rem">
                    <CardNoticias post={post} setCurrentId={setCurrentId}/>
                </Grid>
            ))}
              </Grid>
            </Grid>
        </Grid>
    )
 } else {
    return (
        <Grid container direction="column">
        <Grid item>
             <Grid container direction="column">
               <Grid item className={style.text}><Typography variant="h3" className={style.textstyle}>Noticias</Typography></Grid>
              <Grid item className={style.text2}><Typography variant="h4" className={style.textstyle}>Ultimas publicaciones</Typography></Grid>
                 <Grid item><img src={noticias} alt="ramas de ejercicio" className={style.ramasimage}/></Grid>
            </Grid>
        </Grid>
        <Grid item className={classes.nonews} alignSelf="center">
           <Typography variant="h6">Cargando publicaciones...</Typography>
            </Grid>
        </Grid>
    )
 }
    

}