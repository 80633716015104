import React, {useState, useEffect} from "react";
import {TextField, Button, Typography, Paper} from "@mui/material";
import FileBase from "react-file-base64";
import useStyles from "./styles";
import {useDispatch, useSelector} from "react-redux";
import { createPost, updatePost } from "../../actions/posts";


export default function Form({currentId, setCurrentId}) {
    const [postData, setPostData] = useState({ title: "", message: "", selectedFile: "" })
    const post = useSelector((state) => currentId ? state.posts.find((p) => p._id === currentId): null)
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        if(post) setPostData(post);
    }, [post])

    const handleSubmit = (e) => {
        e.preventDefault();

        if(currentId) {
            dispatch(updatePost(currentId, postData));
        } else {
            dispatch(createPost(postData));
        }
        clear();
    }

    const clear = () => {
        setCurrentId(null);
        setPostData({ title: "", message: "", selectedFile: "" })
    }

    return (
    <Paper className={classes.paper}>
        <form autoComplete="off" noValidate className={`${classes.root} ${classes.form}`}>
            <Typography variant="h6">{currentId ? "Editar" : "Agregar"} Novedad</Typography>
            <TextField name="title" variant="outlined" label="Título" fullWidth value={postData.title} onChange={(e) => setPostData({ ...postData, title: e.target.value})}/>
            <TextField multiline  rows={20} name="message" variant="outlined" label="Texto" fullWidth value={postData.message} onChange={(e) => setPostData({ ...postData, message: e.target.value})}/>
        </form>
        <div className={classes.fileInput}>
            <FileBase type="file" multiple={false} onDone={({base64}) => setPostData({...postData, selectedFile: base64})}></FileBase>
        </div>
        <Button className={classes.buttonSubmit} onClick={handleSubmit} variant="contained" color="secondary" size="large" type="submit" fullWidth>{currentId ? "Actualizar" : "Crear"} </Button>
    </Paper>
    )
    
}