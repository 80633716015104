import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

import derechoparticulares from "../../images/personales.jpeg";

export default function Cardemparticulares() {
  return (
    <Card sx={{ minHeight: 475 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="235rem"
          image={derechoparticulares}
          alt="Derecho Laboral"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Particulares
          </Typography>
          <Typography variant="h6" color="text.secondary">
          El procedimiento de atención es inicialmente una consulta, donde el profesional especializado del estudio, dependiendo la temática que consulte el cliente, lo escuchará y orientará en los pasos a seguir. Hacé click para saber más...
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}