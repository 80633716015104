import { createTheme } from "@mui/material/styles";
import { green,} from '@mui/material/colors';

const transpatent = "#00000000";

 const theme = createTheme({
  palette: {
    common: {
        grey: "#EEEEEE",
        ligthgrey: "#F6F6F6",
        darkgrey: "#7e7b7b"
    },
    primary: {
      main: transpatent,
    },
    secondary: {
      main: green[500],
    },
  },
  typography: {
    tabs: {
        fontFamily: "Poppins",
        textTransform: "none",
        fontWeight: 500,
        fontSize: "1rem",
    },
    allVariants: {
      fontFamily: "Cambria",
      textTransform: "none",
    },
}
});

export default theme
