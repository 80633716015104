import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

import derechofamiliar from "../../images/derechofamiliar.jpeg";

export default function Cardfamiliar() {
  return (
    <Card sx={{ minHeight: 475 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="235rem"
          image={derechofamiliar}
          alt="Derecho Familiar"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Derecho Familiar
          </Typography>
          <Typography variant="h6" color="text.secondary">
          Demandas por alimentos, régimen de comunicación, filiación, impugnación de paternidad, guarda y  adopción, acuerdos de responsabilidad parental, declaratoria de herederos y Sucesiones, Divorcios y División de bienes.
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}