import React, {useEffect, useState} from "react";
import {Container, Typography, Grow, Grid, AppBar, Paper, TextField, Button} from "@mui/material"
import Form from "../Form/Form";
import Posts from "../Posts/Posts";
import useStyles from "./styles";
import news from "../../images/logo_email.png";
import style from "./Admin.module.css"
import { useDispatch } from "react-redux";
import {getPosts} from "../../actions/posts.js"

export default function Admin() {
    const [currentId, setCurrentId] = useState(null);
    const [currentcre, setCurrentcre] = useState(false);
    const [invalid, setinvalid] = useState(false)
    const [postData, setPostData] = useState({ name: "", pass: ""})
    const classes = useStyles();
    const dispatch = useDispatch();
    const BASE_NAME = process.env.REACT_APP_NAMELOGIN;
    const BASE_PASS = process.env.REACT_APP_PASSWORDLOGIN;

    useEffect(() => {
        dispatch(getPosts())
    }, [dispatch]);


    const handleSubmit = (e) => {
        e.preventDefault();
            if (postData.pass === BASE_PASS && postData.name === BASE_NAME){
         setCurrentcre(true)
} else {
    setinvalid(true)
}
    }

    const clear = () => {
        setPostData({ name: "", email: "", startTime: "" })
    }


if (currentcre === true) {
    return (
        <div className={style.mainContainer}>
        <Container maxWidth="lg">
            <Paper className={classes.appBar} position="static" color="inherit" elevation={6}>
                <Typography className={classes.heading} variant="h2" align="center">Novedades</Typography>
                <img className={classes.image} src={news} alt="news" height="70px"></img>
            </Paper>
            <Grow in>
                <Grid container justify="space-between" alignItems="stretch" spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <Form currentId={currentId} setCurrentId={setCurrentId} ></Form>
                    </Grid>
                    <Grid item  xs={12} sm={12}>
                        <Posts setCurrentId={setCurrentId} ></Posts>
                    </Grid>
                </Grid>
            </Grow>
        </Container>
        </div>
        )
} 

if (currentcre === false) {
    return (
        <div className={style.mainContainer2}>
        <Paper className={classes.paper} style={{backgroundColor: "#FFEBEB"}}>
            <form autoComplete="off" noValidate className={`${classes.root} ${classes.form}`}>
                <Typography variant="h6">Login</Typography>
                <TextField  name="title" variant="outlined" label="usuário" fullWidth value={postData.name} onChange={(e) => setPostData({ ...postData, name: e.target.value})}/>
                <TextField type="password" name="message" variant="outlined" label="clave" fullWidth value={postData.pass} onChange={(e) => setPostData({ ...postData, pass: e.target.value})}/>
                {invalid === true && (<Typography color="error" variant="h6">Credenciales inválidas</Typography>)}
            </form>
            <Button className={classes.buttonSubmit} onClick={handleSubmit} variant="contained" color="secondary" size="small" type="submit" fullWidth>Entrar</Button>
        </Paper>
        </div>
        )
}

    

}