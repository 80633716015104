import React, { useState } from "react"
import { Grid, AppBar, Tabs, Tab, useTheme, useMediaQuery, Button} from "@mui/material";
import { useScrollTrigger } from "@mui/material";
import { Link } from 'react-router-dom'
import {Icon} from 'react-icons-kit'
import {menu} from 'react-icons-kit/feather/menu'
import {x} from 'react-icons-kit/feather/x'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';



//import news from "../../images/logo_white.png";
import news from "../../images/logo2.png";
import style from "./Navbar.module.css"

function ElevationScroll(props) {
    const url = window.location.href.split('/')
    const urlclassname = url[url.length-1] === ""? "transparent" : "white";
    const transparent = style.transparent
    const white = style.white
    const { children } = props;
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
    })

   if(urlclassname === "transparent") {
    return React.cloneElement(children, {
        className: trigger ? white : transparent,
      })
   } else
    return React.cloneElement(children, {
      className: white,
    })
  }




export default function Navbar () {
  const theme = useTheme()
   const matches = useMediaQuery(theme.breakpoints.down('md'))
    const url = window.location.href.split('/')
    const urlclassname = url[url.length-1] === ""? "transparent" : "white";
    const transparent = style.transparent
    const white = style.white


      const [toggle, setToggle]=useState(false);

  const handleToggle=()=>{
    setToggle(!toggle);
  }
   

    const drawer = (
      <React.Fragment>
    <nav className={toggle?`${style.navbar} ${style.expanded}`:style.navbar1}>
    <Link to="/">
        <img src={news} className={style.logo1}/>
        </Link>
        <div className={style.toggleicon} onClick={handleToggle}>
          {toggle?<Icon icon={x} size={28}/>:<Icon icon={menu} size={28}/>}
        </div>
        <ul className={style.links}>
          <li><a href="/">Sobre mi</a></li>
          <li><a href="/ramasdeejercicio">Ramas de Ejercicio</a></li>
          <li><a href="/servicios">Servicios</a></li>
          <li><a href="/noticias">Noticias</a></li>
          <li><a href="/contacto">Contacto</a></li>
        </ul>
    </nav>
      </React.Fragment>)

    return (
      <div>
        <AppBar elevation={0}>
            <ElevationScroll>
            {matches ? drawer :
            <Grid container direction="row" justifyContent="center" className={urlclassname === "transparent"? transparent : white}>
                <Grid item className={style.leftTabs}>
                  <Tabs value={false}>
                    <Tab component={Link} to='/' value={1} label={<span className={style.tabtext}>Sobre mi</span>} className={style.tab}/>
                    <Tab component={Link} to='/ramasdeejercicio' value={2} label={<span className={style.tabtext}>Ramas de Ejercicio</span>} className={style.tab}/>
                    <Tab component={Link} to='/servicios' value={3} label={<span className={style.tabtext}>Servicios</span>} className={style.tab}/>
                   </Tabs>
                </Grid>
                <Grid item justifySelf="center">
                  <Link to="/">
                  <img src={news} alt="abogadaNeuquen" className={style.logo}></img>
                  </Link>
                </Grid>
                <Grid item className={style.rigthTabs}>
                <Tabs value={false}>
                    <Tab component={Link} to='/noticias' value={4} label={<span className={style.tabtext}>Noticias</span>} className={style.tab}/>
                    <Tab component={Link} to='/contacto' value={5} label={<span className={style.tabtext}>Contacto</span>} className={style.tab}/>
                </Tabs>
                </Grid>
            </Grid> 
             }
            </ElevationScroll>
        </AppBar> 
        <div className={matches? style.box2 : style.box} >
        <Button className={style.WhatsAppIcon} href="https://wa.me/message/2C5TC2WOP6DUO1" variant="contained" color="success"><WhatsAppIcon fontSize="large"></WhatsAppIcon></Button>
        </div>
        </div>
    )
}