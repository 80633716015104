import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

import derechoempresarial from "../../images/derechoempresarial.jpeg";

export default function Cardempresarial() {
  return (
    <Card sx={{ minHeight: 475 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="235rem"
          image={derechoempresarial}
          alt="Derecho Penal"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Derecho Empresarial
          </Typography>
          <Typography variant="h6" color="text.secondary">
          Asesoramiento preventivo a empresas y PYMES, ofrecemos fiscalización y análisis de la plataforma laboral, contratos, liquidaciones y estrategias preventivas de personal , sueldos, registros legales.  Consulte por abonos mensuales.
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}