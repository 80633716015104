import React from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from "@mui/material/styles";
import theme from './components/ui/theme.js';
import Admin from "./components/Admin/Admin";
import Navbar from "./components/Navbar/Navbar";
import Home from "./components/Home/Home"
import Ramasdeejercicio from "./components/Ramasdeejercicio/Ramasdeejercicio"
import Servicios from "./components/Servicios/Servicios"
import Noticias from "./components/Noticias/Noticias"
import Contacto from "./components/Contacto/Contacto.js";
import Particulares from "./components/Particulares/Particulares.js";
import Empresas from "./components/Empresas/Empresas.js";
import Detalhes from "./components/Noticias/Detalhes.js";
import Mediacion from "./components/Mediacion/Mediacion.js";


const App = () => {

    return (
        <ThemeProvider theme={theme}>
        <BrowserRouter>
        <Navbar></Navbar>
        <Routes>
        <Route path="/" element={<Home/>} /> 
        <Route path="/ramasdeejercicio" element={<Ramasdeejercicio/>} />
        <Route path="/servicios" element={<Servicios/>} /> 
        <Route path="/noticias" element={<Noticias/>} /> 
        <Route path="/contacto" element={<Contacto/>} /> 
        <Route path="/administrar" element={<Admin/>} />
        <Route path="/particulares" element={<Particulares/>}/>
        <Route path="/empresas" element={<Empresas/>} />
        <Route path="/mediaciones" element={<Mediacion/>} />
        <Route path="/noticias/:id" element={<Detalhes/>} />
        </Routes>
        </BrowserRouter>
        </ThemeProvider>
    )
}

export default App