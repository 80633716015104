import React from "react"
import { Grid, Link, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';



import style from "./Servicios.module.css"
import Cardemparticulares from "./Cardparticulares";
import servicios from "../../images/servicios.jpeg";
import Cardempresas from "./Cardempresas";
import Cardmediacion from "./Cardmediacion";

let theme = createTheme();
theme = responsiveFontSizes(theme);

const useStyles = makeStyles({
    grid: {
        width: '100%'
    },
    cards: {
        marginTop: "10rem",
        textDecoration: "none"
    },

})



export default function Servicios () {
    const classes = useStyles()
    return (
        <Grid container direction="column">
            <Grid item>
                 <Grid container direction="column">
                   <Grid item className={style.text}><Typography variant="h3" className={style.textstyle}>Servicios</Typography></Grid>
                  <Grid item className={style.text2}><Typography variant="h5" className={style.textstyle}>A particulares y a empresas....</Typography></Grid>
                     <Grid item><img src={servicios} alt="ramas de ejercicio" className={style.ramasimage}/></Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.cards}>
              <Grid container direction="row" spacing={5} justifyContent="center" alignItems="center">
              <Grid item maxWidth="35rem" ><Link href="/particulares" style={{ textDecoration: 'none' }}><Cardemparticulares ></Cardemparticulares></Link></Grid>
            <Grid item maxWidth="35rem"><Link href="/empresas" style={{ textDecoration: 'none' }}><Cardempresas></Cardempresas></Link></Grid>
            <Grid item maxWidth="35rem"><Link href="/mediaciones" style={{ textDecoration: 'none' }}><Cardmediacion></Cardmediacion></Link></Grid>
              </Grid>
            </Grid>
        </Grid>
    )

}