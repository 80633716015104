import React from "react"
import { Grid, Link, Typography, Button, Icon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocationOnIcon from '@mui/icons-material/LocationOn';


import network from "../../images/network2.jpeg";
import style from "./Contacto.module.css"

let theme = createTheme();
theme = responsiveFontSizes(theme);


const useStyles = makeStyles({
    grid: {
        width: '100%'
    },
    bio2Text: {
        [theme.breakpoints.down('md')]: {
            marginTop:  "10rem",
            marginRight: "0.5rem",
            marginLeft: "0.5rem",
        },   [theme.breakpoints.up('md')]: {
            marginTop:  "10rem",
            marginLeft: "3rem",
            marginRight: "3rem",
        }
             },

    textcontact: {
            [theme.breakpoints.down('sm')]: {
                    marginRight: "3rem",
                    marginLeft: "3rem",
                },

                [theme.breakpoints.down('md')]: {
                    marginRight: "5rem",
                    marginLeft: "5rem"
                },
                [theme.breakpoints.up('md')]: {
                    marginRight: "10rem",
                    marginLeft: "10rem",
                },
                [theme.breakpoints.up('lg')]: {
                    marginRight: "8rem",
                    marginLeft: "8rem",
                },
              },

              container: {
                [theme.breakpoints.down('sm')]: {
                    maxWidth: "23rem"
                    
                    },
                  },
                  address: {
                    marginTop: "3rem",
                    backgroundColor: "#D6E4E5",
                    minHeight: "10rem"
                },

})


export default function Contacto () {
    const classes = useStyles()
    return (
        <Grid container direction="column">
        <Grid item>
             <Grid container direction="column">
               <Grid item className={style.text}><Typography variant="h3" className={style.textstyle}>Contacto</Typography></Grid>
              <Grid item className={style.text2}><Typography variant="h4" className={style.textstyle}>Agende una cita</Typography></Grid>
                 <Grid item><img src={network} alt="ramas de ejercicio" className={style.ramasimage}/></Grid>
            </Grid>
        </Grid>
        <Grid item className={classes.bio2Text}><Typography variant="h6">La consulta personal o por videollamada, ofrece a nuestro cliente el asesoramiento integro pertinente, en ésta se le hace saber mediante el análisis particular del caso, cual es el desarrollo del proceso, costo y tiempo que implicará su resolución. En esta instancia ofrecemos también revisión de documentación y derivamos a nuestros profesionales interdisciplinarios en caso de corresponder, es decir a contadores, psicólogos, consteladores, peritos etc.  Desde la primer consulta nuestro cliente obtiene una visión realista de su situación.</Typography></Grid>
        <Grid item>
                <Grid container direction="row" justifyContent="center" alignItems="center" className={classes.container}>
                  <Grid className={classes.textcontact} >
                  <Grid container direction="column" >
                    <Typography variant="h6" className={style.text1}>Whatsapp</Typography>
                    <Button href="https://wa.me/message/2C5TC2WOP6DUO1" variant="contained" color="success">Envíe un mensaje<WhatsAppIcon></WhatsAppIcon></Button>
                    </Grid>
                    </Grid>
                  <Grid className={classes.textcontact}>
                  <Grid container direction="column" >
                    <Typography variant="h6" className={style.text1}>Correo Electrónico</Typography>
                    <Typography variant="h6">abogadaneuquen@hotmail.com</Typography>
                    </Grid>
                    </Grid>
                  <Grid className={classes.textcontact}>
                  <Grid container direction="column" >
                    <Typography variant="h6" className={style.text1}>Llamar al</Typography>
                    <Typography variant="h6">0299 465-1168</Typography>
                    </Grid>
                    </Grid>
                  </Grid>
                  <Grid container direction="column" alignItems='center' justifyContent="center" spacing={1} className={classes.address}>
            <Grid item><LocationOnIcon color="error"></LocationOnIcon></Grid>
            <Grid item><Typography color="#577D86" variant="h6">Santa Fe 543, primer piso, oficina 4</Typography></Grid>
            <Grid item><Typography color="#577D86" variant="h5">Neuquén Capital</Typography></Grid>

        </Grid>
        </Grid>

    </Grid>
    )

}