import {makeStyles} from "@material-ui/styles"

export default makeStyles((theme) => ({
    mainContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    smMargin: {
      margin: "1rem",
    },
    actionDiv: {
      textAlign: 'center',
    },
  }));